import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import { Link } from "gatsby"

// Service Images
import ServiceImage1 from "../assets/images/services-image/service-image1.png"
import ServiceImage2 from "../assets/images/services-image/service-image2.png"
import ServiceImage3 from "../assets/images/services-image/service-image3.png"
import ServiceImage4 from "../assets/images/services-image/service-image4.png"
import ServiceImage5 from "../assets/images/services-image/service-image5.png"
import ServiceImage6 from "../assets/images/services-image/service-image6.png"
import Simulation from "../assets/images/iot-features-image/iot-feature-image2.png"
import Manufacturing2 from "../assets/images/manufacturing2.png"

// Shape Images
import Shape1 from "../assets/images/shape1.png"
import Shape2 from "../assets/images/shape2.svg"
import Shape3 from "../assets/images/shape3.svg"
import Shape4 from "../assets/images/shape4.svg"

const Services = () => (
  <Layout>
    <SEO title="Services" />

    <Navbar />

    <div className="ml-services-area ptb-80">
      <div className="container">
        <div className="section-title">
          {/* <span className="sub-title">Our Solutions</span> */}
          <h2>Our Services</h2>
          <p>
            Faraya provides a streamlined suite of services that allow us to
            successfully implement well-designed solutions to address our
            client's complex challenges.
          </p>
        </div>

        <div className="row justify-content-center">
          {/* <div className="col-lg-4 col-sm-6">
                    <Link to="/engineering">
                        <div className="single-ml-services-box">
                            <div className="image">
                                <img src={ServiceImage5} alt="image" />
                            </div>
                            <h3>
                                
                                    Engineering Services
                      
                            </h3>
                            <p>We design and implement engineering systems. From CAD Models to rapidly produced, functional prototypes.</p>
                        </div>
                        </Link>
                    </div> */}

          <div className="col-lg-4 col-sm-6">
            <Link to="/software">
              <div className="single-ml-services-box">
                <div className="image">
                  <img src={ServiceImage2} alt="image" />
                </div>
                <h3>Full-Stack Software Development</h3>
                <p>
                  We develop intuitive, data-driven web, mobile, and AR/VR
                  applications.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-sm-6">
            <Link to="/design">
              <div className="single-ml-services-box">
                <div className="image">
                  <img src={ServiceImage3} alt="image" />
                </div>
                <h3>UI/UX Design</h3>
                <p>
                  Our human factors scientists are trained to optimize products,
                  software, and marketing campaigns.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-sm-6">
            <Link to="/analytics">
              <div className="single-ml-services-box">
                <div className="image">
                  <img src={ServiceImage1} alt="image" />
                </div>
                <h3>Machine Learning & Big Data Analytics</h3>
                <p>
                  Our data scientists stand at the ready to implement
                  predictive, A.I algorithms to meet your product or business
                  needs.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-sm-6">
            <Link to="/manufacturing">
              <div className="single-ml-services-box">
                <div className="image">
                  <img src={Manufacturing2} alt="image" />
                </div>
                <h3>Manufacturing Services</h3>
                <p>
                  We leverage our global relationships to quickly establish
                  manufacturing supply chains to produce your product at a low
                  cost.
                </p>
              </div>
            </Link>
          </div>

          {/* <div className="col-lg-4 col-sm-6">
                    <Link to="/research">
                        <div className="single-ml-services-box">
                            <div className="image">
                                <img src={ServiceImage4} alt="image" />
                            </div>
                            <h3>
                
                                    Research & Patenting
                       
                            </h3>
                            <p>Our law team and PhD researchers are able to ensure that your solution is research-informed and that your IP is protected.</p>
                        </div>
                        </Link>
                    </div> */}

          <div className="col-lg-4 col-sm-6">
            <Link to="/simulation">
              <div className="single-ml-services-box">
                <div className="image">
                  <img width={"60%"} src={Simulation} alt="image" />
                </div>
                <h3>Simulation & Modeling</h3>
                <p>
                  We leverage system dynamics, agent-based, and physics models
                  to simulate, evaluate, and inform the design of complex
                  systems.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-sm-6">
            <Link to="/marketing">
              <div className="single-ml-services-box">
                <div className="image">
                  <img src={ServiceImage6} alt="image" />
                </div>
                <h3>Marketing & Communications</h3>
                <p>
                  Our team can meet your content creation need and establish
                  targeted advertising campaigns across the web for your
                  products.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape2 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
      <div className="shape3">
        <img src={Shape3} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape7">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </div>

    <Footer />
  </Layout>
)

export default Services
